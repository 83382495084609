import { createElement, FunctionComponent, ComponentClass } from 'react';
import { Provider } from 'react-redux';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { PersistGate } from 'redux-persist/integration/react';
import reduxStore from 'redux/store';
import { ThemeProvider } from 'contexts/ThemeContext';
import { I18nextProvider } from 'react-i18next';
import i18n from 'services/i18n';
import { NotificationsProvider } from 'contexts/NotificationsContext';
import { NotificationsWrapper } from 'components/common/Notifications/NotificationsWrapper';
import { REACT_QUERY_CACHE_BUSTER } from 'utils';
import { useLocaleDate } from 'hooks';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export interface ReduxWrappedComponentInterface {
  component: string | FunctionComponent<unknown> | ComponentClass<unknown, unknown>,
  props: unknown,
}

export const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      /**
       * Some queries are cached in the other parts of the app,
       * so we don't want to cache them here,
       * but we can override this in each query
       */
      staleTime: 0,
      gcTime: 0,
    },
  },
});

const syncStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

const ReduxWrappedComponent = ({ component, props }: ReduxWrappedComponentInterface) => {
  const { store, persistor } = reduxStore;
  useLocaleDate();

  return (
    <I18nextProvider i18n={i18n}>
      <PersistQueryClientProvider
        client={reactQueryClient}
        persistOptions={{
          persister: syncStoragePersister,
          buster: REACT_QUERY_CACHE_BUSTER,
        }}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider>
              <NotificationsProvider>
                <NotificationsWrapper />
                {createElement(component, props)}
              </NotificationsProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </PersistQueryClientProvider>
    </I18nextProvider>
  );
};

export default ReduxWrappedComponent;
