export function createQueryString(data): string {
  const qs = Object.keys(data).filter((key) => data[key] !== undefined)
    .map((k) => {
      if (typeof data[k] === 'object') {
        let querySegment = '';
        data[k].forEach((item, index) => {
          querySegment +=
            `${encodeURIComponent(k)}[]=${encodeURIComponent(item)}`;
          if (index < data[k].length - 1) {
            querySegment += '&';
          }
        });
        return querySegment;
      }
      return `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`;

    })
    .join('&');

  return qs.length > 0 ? `?${qs}` : '';
}

export const createApiStringFromObject = (obj) => Object.values(obj).map((x, i) => `${Object.keys(obj)[i]}=${x}`).join('&');
