import {
  DateFormatDict,
  DurationFormat,
  FeatureFlag,
  GroupSetting,
  SettingsModelStateFields,
  Theme,
  ThemeDictionary,
  UserSetting,
  WeekStart,
} from './types';

export const getDefaultValue = (type: SettingsModelStateFields, name) => {
  switch (type) {
    case SettingsModelStateFields.USER_SETTING:
      return getDefaultValueForUserSetting(name);

    case SettingsModelStateFields.GROUP_SETTING:
      return getDefaultValueForGroupSetting(name);

    case SettingsModelStateFields.FEATURE_FLAG:
      return getDefaultValueForFeatureFlag(name);

    default:
      return null;
  }
};

const getDefaultValueForUserSetting = (name: UserSetting) => {
  switch (name) {
    case UserSetting.THEME:
      return ThemeDictionary[Theme.DEFAULT];

    default:
      return null;
  }
};

const getDefaultValueForGroupSetting = (name: GroupSetting) => {
  switch (name) {
    case GroupSetting.DURATION_FORMAT:
      return DurationFormat[DurationFormat.FORMAT_CLASSIC];

    case GroupSetting.WEEK_START:
      return WeekStart[WeekStart.MONDAY];

    case GroupSetting.CHANGE_BILLING_FLAG:
      return false;

    case GroupSetting.ALLOW_TRACKING_TIME_FOR_ARCHIVED_TASKS:
      return false;

    case GroupSetting.DATE_FORMAT:
      return DateFormatDict.FORMAT_YMD;

    default:
      return null;
  }
};

const getDefaultValueForFeatureFlag = (name: FeatureFlag) => {
  switch (name) {
    default:
      return null;
  }
};
