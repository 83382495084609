import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';

export class ApiClientTimer extends ApiClientBase {
  async get(params: {
    action: 'status' | 'start' | 'stop';
  } = {
    action: 'status',
  }) {
    const { action } = params;

    return await this.fetchData<any>({
      queryString: 'timer',
      apiVersion: 1,
      method: 'POST',
      body: {
        action,
        service: 'tc_web',
      },
    });
  }
}
