export class EntryPermissionChecker {

  private usersPrivilagesMap: Record<string, boolean> = {};

  constructor(usersPrivilagesArray: string[]) {
    usersPrivilagesArray.forEach((userId) => {
      this.usersPrivilagesMap[parseInt(userId)] = true;
    });
  }
  canEdit(entry): boolean {
    return this.usersPrivilagesMap[parseInt(entry.userId)];
  }

  canDelete(entry): boolean {
    return this.usersPrivilagesMap[parseInt(entry.userId)];
  }
}
