import React, { createContext, useContext, useEffect, useState } from 'react';
import { defaultThemeContext, Theme } from 'contexts/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectTheme, setThemeSetting, Theme as SettingTheme } from 'redux/models/SettingModel';
import { IChildren } from 'types';

const ThemeContext = createContext(defaultThemeContext);

const settingThemeMapper = (theme: SettingTheme) => {
  if (theme === SettingTheme.DARKMODE) return Theme.DARK;

  return Theme.MODERN;
};

const ThemeProvider = ({ children }: IChildren) => {
  const selectedTheme = useSelector((state) => selectTheme(state)) || Theme.MODERN;
  const [theme, setTheme] = useState<Theme>(selectedTheme);
  const dispatch = useDispatch();

  const setThemeHandler = (e) => {
    const { theme } = e.detail;
    setTheme(settingThemeMapper(theme));
    document.body.setAttribute('data-theme', theme);
  };

  useEffect(() => {
    window.addEventListener('theme-changed', setThemeHandler);

    return () => {
      window.removeEventListener('theme-changed', setThemeHandler);
    };
  }, []);

  useEffect(() => {
    const setThemeOnSaveSettings = (e) => {
      setThemeHandler(e);
      dispatch(setThemeSetting(settingThemeMapper(e.detail.theme)));
    };

    window.addEventListener('settings-saved', setThemeOnSaveSettings);

    return () => {
      window.removeEventListener('settings-saved', setThemeOnSaveSettings);
    };
  }, []);

  useEffect(() => {
    if (selectedTheme) {
      setTheme(selectedTheme);
    }
  }, [selectedTheme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

const useThemeContext = () => useContext(ThemeContext);

export { ThemeProvider, ThemeContext, useThemeContext };
