import { translate } from 'utils/translate';
import { durationFormatter } from 'services/index';
import { DurationFormat, ReportEntityType } from 'types';
import { GoogleSpreadsheetDataTransformerInterface } from './GoogleSpreadsheetDataTransformerInterface';
import { ReportEntityDataProviderInterface } from 'services/ReportEntityDataProvider/ReportEntityDataProviderInterface';
import { ReportType } from 'redux/models/ReportModel/types';

export default class GoogleSpreadsheetDataTransformer
implements GoogleSpreadsheetDataTransformerInterface {
  private reportEntityDataProvider: ReportEntityDataProviderInterface;
  private dataToTransform;
  private reportType: ReportType;

  constructor(reportEntityDataProvider: ReportEntityDataProviderInterface) {
    this.reportEntityDataProvider = reportEntityDataProvider;
  }

  transform(data: unknown, durationFormat: DurationFormat, type?: ReportType) {
    this.reportType = type;
    this.dataToTransform = data;
    return {
      [translate('GoogleSpreadsheetDataTransformer.time_tracking_report')]:
        this.#createSheet(durationFormat),
      [translate('GoogleSpreadsheetDataTransformer.time_tracking_report_decimal')]:
        this.#createSheet(DurationFormat.FORMAT_DECIMAL_WITH_DOT),
    };
  }

  #createSheet(durationFormat) {
    let sheet = [];
    sheet = this.#addTitle(sheet);
    sheet = this.#addContent(sheet, durationFormat);
    sheet = this.#addTotalRow(sheet, durationFormat);

    return sheet;
  }

  #addTitle(sheet) {
    const title = [];
    switch (this.reportType) {
      case ReportType.DETAILED_REPORT:
        title.push(translate('GoogleSpreadsheetDataTransformer.date'));
        title.push(translate('GoogleSpreadsheetDataTransformer.user'));
        title.push(translate('GoogleSpreadsheetDataTransformer.tags'));
        title.push(translate('GoogleSpreadsheetDataTransformer.hours'));
        title.push(translate('GoogleSpreadsheetDataTransformer.time'));
        title.push(translate('GoogleSpreadsheetDataTransformer.billable'));
        title.push(translate('GoogleSpreadsheetDataTransformer.note'));
        title.push(translate('GoogleSpreadsheetDataTransformer.level_1'));
        title.push(translate('GoogleSpreadsheetDataTransformer.level_2'));
        title.push(translate('GoogleSpreadsheetDataTransformer.level_3'));
        title.push(translate('GoogleSpreadsheetDataTransformer.level_4'));
        title.push(translate('GoogleSpreadsheetDataTransformer.name'));
        break;
      case ReportType.SUMMARY_REPORT:
        title.push(translate('GoogleSpreadsheetDataTransformerSummary.name'));
        title.push(translate('GoogleSpreadsheetDataTransformerSummary.hours'));
        break;
      default:
        console.warn('No type of report');
    }

    sheet.push(title);
    return sheet;
  }

  #addContent(sheet, durationFormat) {
    this.dataToTransform.data.forEach((item) => {
      sheet = this.#addContentItem(item, 0, sheet, durationFormat);
    });

    return sheet;
  }

  #addContentItem(item, level, sheet, durationFormat) {
    const content = [];
    const indentString = new Array(level + 1).join('    ');
    let name = '';
    let taskBreadcrumb = '';
    let tagsString = [];
    let partsOfTaskBreadcrumb = [];

    switch (this.reportType) {
      case ReportType.SUMMARY_REPORT:
        name = this.reportEntityDataProvider.getItemName(item.id, item.type);
        content.push(indentString + name);
        content.push(this.#formatDuration(item.duration, durationFormat));
        sheet.push(content);
        item.children.forEach((child) => {
          sheet = this.#addContentItem(child, level + 1, sheet, durationFormat);
        });
        break;
      case ReportType.DETAILED_REPORT:
        name = this.reportEntityDataProvider.getItemName(item.taskId, ReportEntityType.TASK);
        taskBreadcrumb = this.reportEntityDataProvider.getItemBreadcrumb(item.taskId, ReportEntityType.TASK);
        partsOfTaskBreadcrumb = taskBreadcrumb.split('/').map(part => part.trim());
        tagsString = item.tags.map((tagId) => this.reportEntityDataProvider.getItemName(tagId, ReportEntityType.TAG));
        content.push(item.date);
        content.push(item.userName);
        content.push(tagsString.join(','));
        content.push(this.#formatDuration(item.duration, durationFormat));
        content.push(`${item.startTime} - ${item.endTime}`);
        content.push(
          item.billable
            ? translate('GoogleSpreadsheetDataTransformer.billable')
            : translate('GoogleSpreadsheetDataTransformer.no_billable'),
        );
        content.push(item.note);
        content.push(partsOfTaskBreadcrumb[0]);
        content.push(partsOfTaskBreadcrumb[1]);
        content.push(partsOfTaskBreadcrumb[2]);
        content.push(partsOfTaskBreadcrumb[3]);
        content.push(name);
        sheet.push(content);
        break;
    }

    return sheet;
  }

  #addTotalRow(sheet, durationFormat) {
    const totalRow = [];
    const emptyCell = '';
    const cellsToMoveTotalTime = 3;
    for (let i = 0; i < cellsToMoveTotalTime; i++) {
      totalRow.push(emptyCell);
    }
    totalRow.push(translate('GoogleSpreadsheetDataTransformer.total'));
    totalRow.push(
      this.#formatDuration(this.dataToTransform.totalTime, durationFormat),
    );

    sheet.push(totalRow);

    return sheet;
  }

  #formatDuration(raw, durationFormat) {
    let formattedDuration: number | string = durationFormatter
      .default(raw, durationFormat)
      .trim();

    if (durationFormat === DurationFormat.FORMAT_DECIMAL_WITH_DOT) {
      formattedDuration = parseFloat(formattedDuration);
    }

    return formattedDuration;
  }
}
