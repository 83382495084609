import { ConfigInterface } from './interfaces';
import TimeServiceFactory from './TimeServiceFactory';
import ClientFactory from './RestApiClientFactory';
import StorageFactory from './BrowserStorageService';
import NotificationServiceFactory from './NotificationService';
import { StorageTypes } from './BrowserStorageService/types';
import { GroupSetting, SettingsModelStateFields } from 'redux/models/SettingModel/types';
import GoogleSpreadsheetExporter from './GoogleSpreadsheetExporter/GoogleSpreadsheetExporter';
import GoogleSpreadsheetDataTransformer from './GoogleSpreadsheetExporter/GoogleSpreadsheetDataTransformer';
import ReportEntityDataProvider from './ReportEntityDataProvider/ReportEntityDataProvider';
import DurationFormatter from './DurationFormatter/DurationFormatter';
import ColorService from './ColorService/ColorService';
import { DEFAULT_LANGUAGE } from './i18n';
import TimeFormatter from './TimeFormatter/TimeFormatter';
import DateFormatter from './DateFormatter/DateFormatter';
import { EntryPermissionChecker } from 'security/permissions/checkers/EntryPermissionChecker';
import { ApiTokenService } from 'services/ApiTokenService/ApiTokenService';
import { FeatureFlags } from 'types';

// If you change this, remember to also modify other places:
// - .storybook/preview-head.html
// - application/controllers/Rest/ApiFrontendConfig.php
// - application/views/dashboard/_header.php
// - res/react/services/interfaces.tsx
export let config: ConfigInterface = {
  baseUrl: '',
  helpCenterPageUrl: 'https://help.timecamp.com/help',
  kioskPageUrl: 'https://kiosk.timecamp.io/',
  kioskHelpPage: '/kiosk',
  feedbackModalTagId: '44ab48d3-027f-40dc-9d6d-ab4a302f6a1f',
  feedbackWeeklyBugModalTagId: '7cb4e218-0170-4181-8c83-1490c620e859',
  feedbackWeeklyFeedbackModalTagId: 'addad32a-3aaa-4169-a6ac-38343ae4d3db',
  feedbackKioskBugModalTagId: '46f237bc-8d75-410c-9bda-251468f15cfe',
  feedbackKioskFeedbackModalTagId: 'b493ee01-98c1-4950-90ae-8264adaf0e79',
  feedbackAssistantModalTagId: 'aa9ec6b4-1054-472d-94ff-b91f55f34920',
  feedbackExpensesBugModalTagId: '4494411e-3b9b-4700-b0bc-dab5e0c62e77',
  feedbackExpensesFeedbackModalTagId: '6571f0c6-4876-4686-9d5e-abda4f2f1844',
  userId: 0,
  language: DEFAULT_LANGUAGE,
  timezoneOffset: 0,
  rootGroupId: 0,
  storage: 'LocalStorage',
  subscription: {},
  groupSetting: {
    allowTrackingTimeForArchivedTasks: '',
    approvalPeriod: 'weekly',
    changeBillingFlag: '1',
    dateFormat: '',
    defaultTaxId: '-1',
    disallowForceSynchForNonAdmin: '',
    disallowMultiSelectTags: '0',
    durationFormat: '0',
    enabledTimesheets: ['textual', 'weekly', 'graphical'],
    preventOverlappingEntries: '',
    timeFormat: '0',
    weekStart: '1',
  },
  userSetting: {
    doNotSubmitApproval: '1',
    userFavoritesTasks: '1',
    userSuggestedTasks: '1',
  },
  featureFlags: {
    [FeatureFlags.ACCESS_TO_DETAILED_REPORT_V_2022]: false,
    [FeatureFlags.ACCESS_TO_SUMMARY_REPORT_V_2022]: false,
  },
  staticContentBaseUrl: '',
  modules: {
    approvals: '0',
    billing: '0',
    invoicing: '0',
    tags: '0',
    moduleExpenses: '0',
  },
  googleSpreadsheetExportClientId: '',
  googleSpreadsheetExportApiKey: '',
  colors: [],
  productMetadata: {
    productName: '',
  },
  amplitudeApiKey: '',
  canDoOutsideConnection: '0',
  csrfCookieName: '',
  csrfTokenName: '',
  csrfEnabled: false,
  currency: {
    id: '',
    symbol: '',
    symbolPosition: 1,
    name: '',
  },
};

// eslint-disable-next-line
// @ts-ignore
config = { ...config, ...REACT_CONFIG };

export const storageServiceFactory = window.getStorageType && new StorageFactory(
  window.getStorageType(),
);

export const storageSupportAngular = new StorageFactory(
  new Promise((resolve) => {
    resolve(StorageTypes.SAFE_LOCAL_STORAGE);
  }),
);
export const restApiClient = ClientFactory(config);
export const timeService = TimeServiceFactory();
export const durationFormatter = new DurationFormatter();
export const timeFormatter = new TimeFormatter();
export const dateFormatter = new DateFormatter();
export const colorService = new ColorService();
export const apiTokenService = new ApiTokenService();

export const reportEntityDataProvider = new ReportEntityDataProvider();
export const googleSpreadsheetDataTransformer = new GoogleSpreadsheetDataTransformer(reportEntityDataProvider);
export const googleSpreadsheetExporter = new GoogleSpreadsheetExporter(
  googleSpreadsheetDataTransformer,
);

// eslint-disable-next-line
// @ts-ignore
export const entryPermissionChecker = new EntryPermissionChecker(config.permissions?.usersPrivilages || []);

// eslint-disable-next-line
// @ts-ignore
timeService.setWeekStart(
  config[SettingsModelStateFields.GROUP_SETTING][GroupSetting.WEEK_START],
);

export const notificationService = NotificationServiceFactory();

export default {
  config,
  restApiClient,
  timeService,
  storageServiceFactory,
  notificationService,
  googleSpreadsheetExporter,
  googleSpreadsheetDataTransformer,
  durationFormatter,
  timeFormatter,
  dateFormatter,
  colorService,
  apiTokenService,
};
