import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';

export class ApiClientAuth extends ApiClientBase {
  async logout() {
    return await this.fetchData({
      queryString: 'auth/logout/0',
      apiVersion: 0,
    });
  }
}
