import { Map } from 'immutable';

export type TimeSheetPayloadAction<T = any> = {
  type: string;
  payload: T;
};

export enum TimeSheetModelStateFields {
  TASKS_WEEK = 'tasksWeek',
  TASKS_WEEK_LOADING = 'tasksWeekLoading',
  WEEK_BETWEEN_DATE = 'weekBetweenDate',
  WEEK_GROUP_BY = 'weekGroupBy',
  WEEK_ACTIVE_USER = 'weekActiveUser',
  OPEN_TASK_PICKER = 'isOpenTaskPicker',
}

export enum TimeSheetModelActions {
  UPDATE_TASKS_WEEK = 'UPDATE_TASKS_WEEK',
  REMOVE_TASKS_WEEK = 'REMOVE_TASKS_WEEK',
  UPDATE_TASKS_WEEK_LOADING = 'UPDATE_TASKS_WEEK_LOADING',
  UPDATE_WEEK_TIME_ENTRY = 'UPDATE_WEEK_TIME_ENTRY',
  DELETE_WEEK_TIME_ENTRY = 'DELETE_WEEK_TIME_ENTRY',
  ADD_EMPTY_TASKS_WEEK = 'ADD_EMPTY_TASKS_WEEK',
  UPDATE_WEEK_BETWEEN_DATE = 'UPDATE_WEEK_BETWEEN_DATE',
  UPDATE_WEEK_ACTIVE_USER = 'UPDATE_WEEK_ACTIVE_USER',
  SET_WEEK_INLINE_EDIT_ERROR = 'SET_WEEK_INLINE_EDIT_ERROR',
  REMOVE_WEEK_INLINE_EDIT_ERROR = 'REMOVE_WEEK_INLINE_EDIT_ERROR',
  TOGGLE_TASK_PICKER = 'TOGGLE_TASK_PICKER',
}

export type TimeSheetSingleCalendar = {
  [key: string]: number;
};

export type TimeSheetSingleWeek = {
  readonly taskId: number;
  readonly billable?: boolean;
  readonly duration?: number;
  readonly tags?: {
    [key: number]: number[]
  };
  calendar?: TimeSheetSingleCalendar;
};

export type TasksWeek = TimeSheetSingleWeek[] | null;

export type UpdateWeekTimeEntry = Required<Omit<TimeSheetSingleWeek, 'calendar' | 'tags'>
  & {
  taskDate: Date | string;
  tagIds: {
    [key: number]: number[]
  };
  readonly userId: number;
}>;

export type UpdateWeekTimeEntryRequest = {
  entry: UpdateWeekTimeEntry;
  signal: any
};

export type WeekBetweenDate = Date[] | null;

export type WeekGroupBy = string[] | null;

export type TimeSheetModelState = Map<string, any> & {
  [TimeSheetModelStateFields.TASKS_WEEK]: TasksWeek;
  [TimeSheetModelStateFields.TASKS_WEEK_LOADING]: boolean;
  [TimeSheetModelStateFields.WEEK_BETWEEN_DATE]: WeekBetweenDate;
  [TimeSheetModelStateFields.WEEK_GROUP_BY]: WeekGroupBy;
  [TimeSheetModelStateFields.WEEK_ACTIVE_USER]: number;
};
