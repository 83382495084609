export const convertRGBToHex = (R: number, G: number, B: number) => {
  let hexValue = '';

  if (R < 1) {
    R = 0;
  } else if (R > 255) {
    R = 255;
  }

  if (G < 1) {
    G = 0;
  } else if (G > 255) {
    G = 255;
  }

  if (B < 1) {
    B = 0;
  } else if (B > 255) {
    B = 255;
  }

  hexValue = (
    0x1000000 +
    R * 0x10000 +
    G * 0x100 +
    B
  ).toString(16).slice(1);

  return `#${hexValue}`;
};
