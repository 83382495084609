import { Map } from 'immutable';
import { AnyMap } from '../../../types';

export enum ApprovalModelActions {
    FETCH_APPROVALS = 'FETCH_APPROVALS',
    UPDATE_APPROVALS = 'UPDATE_APPROVALS',
    FETCH_ACTION_APPROVALS = 'FETCH_ACTION_APPROVALS',
    FETCH_APPROVALS_SUCCESS = 'FETCH_APPROVALS_SUCCESS',
    LOADING_APPROVALS = 'LOADING_APPROVALS',
}

export enum ApprovalModelStateFields {
    APPROVALS = 'tags',
    LOADING = 'loadingApprovals',
}

export interface ApprovalModelState extends Map<string, any> {
    [ApprovalModelStateFields.APPROVALS]: AnyMap;
    [ApprovalModelStateFields.LOADING]: boolean;
}

export enum ApprovalStatus {
  OPEN = 0,
  APPROVED = 1,
  PENDING = 2,
  REJECTED = 3,
  APPROVED_AUTOMATICALLY = 4,
  APPROVED_PARTIALLY = 5,
  REMINDER_SENT_TO_APPROVER = 6,
  REMINDER_SENT_TO_USER = 7
}
