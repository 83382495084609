import { domain, FeatureFlag } from './index';
import { createSelector } from '@reduxjs/toolkit';
import { getDefaultValue } from './utils';
import {
  CurrencySettingEnum,
  GroupSetting,
  Module,
  Permission,
  RuntimeSetting,
  SettingModelState,
  SettingsModelStateFields,
  UserSetting,
} from './types';

export const selectDomain = (state) => state[domain] as SettingModelState;
export const selectSettings = createSelector(
  selectDomain,
  (_, args: { type: SettingsModelStateFields }) => args.type,
  (domain: any, field) => domain.get(field) || {},
);

export const selectSyncLoading = createSelector(
  selectDomain,
  (domain) => domain.get(SettingsModelStateFields.SYNC_INTEGRATIONS_LOADING) || false,
);

export const selectSetting = createSelector(
  selectSettings,
  (_, args: { name: GroupSetting | UserSetting | RuntimeSetting | Permission | FeatureFlag}) => args.name,
  (_, args: { type }) => args.type,
  (domain: any, name, type: SettingsModelStateFields) => {
    const setting = domain.get(name);

    if (!setting) {
      return getDefaultValue(type, name);
    }

    return setting;
  },
);

export const selectModule = createSelector(
  selectDomain,
  (_, args: { name: Module }) => args.name,
  (domain: any, name) => {
    const module = domain.getIn([SettingsModelStateFields.MODULES, name]);

    if (!module) {
      return getDefaultValue(SettingsModelStateFields.MODULES, name);
    }

    return module;
  },
);

export const selectCurrency = createSelector(selectDomain, (settings) => {
  const type = SettingsModelStateFields.CURRENCY_SETTING;
  const setting = settings.getIn([type, CurrencySettingEnum.CURRENCY]);
  return setting;
});

export const selectTheme = createSelector(selectDomain, (settings) => {
  const type = SettingsModelStateFields.USER_SETTING;
  const name = UserSetting.THEME;
  return settings.getIn([type, name]);
});
