import { Map } from 'immutable';
import { AnyMap } from 'types';

export enum MessagesModelActions {
  CHANGE_MODAL_VISIBILITY = 'CHANGE_MODAL_VISIBILITY',
  CHANGE_MODAL_TEXT = 'CHANGE_MODAL_TEXT',
}

export enum MessagesModelStateFields {
  MODAL_VISIBLITY = 'modalVisibility',
  MODAL_MESSAGE = 'modalMessage',
  MODAL_TITLE = 'modalTitle',
  MIN_PLAN = 'minPlan',
}

export interface MessagesModelState extends Map<string, any> {
  [MessagesModelStateFields.MODAL_VISIBLITY]: AnyMap;
  [MessagesModelStateFields.MODAL_MESSAGE]: AnyMap;
  [MessagesModelStateFields.MODAL_TITLE]: AnyMap;
  [MessagesModelStateFields.MIN_PLAN]: AnyMap;
}

export enum Modal {
  NO_ACCESS_TO_FEATURE_MODAL = 'noAccessToFeature',
  CONFIRMATION_MODAL = 'confirmationModal'
}
