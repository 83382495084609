import { defaultFetchUsersAndGroupTreeParams } from 'reactQuery';

import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';
import {
  IFetchUsersAndGroupTreeResponse,
  IPostInviteUsersByEmailParams,
  IGetInviteUsersLink,
  IInviteUsersBulk,
  IPeoplePickerParams,
  IPostInviteUserByEmailResponse,
} from 'services/RestApiClientFactory';
import { config } from 'services';
import { getCSRFCookieValue } from 'utils';

export class ApiClientUsers extends ApiClientBase {

  async fetchUsers(signal: AbortSignal) {
    const { data } = await this.fetchData({
      queryString: 'users',
      apiVersion: 1,
      signal,
    });

    return data;
  }

  public async peoplePicker(signal: AbortSignal, params: IPeoplePickerParams = defaultFetchUsersAndGroupTreeParams) {
    const { filters, options } = params;
    let queryString = '';

    if (filters) {
      queryString += Object.keys(filters).map(key => `filters[${key}]=${filters[key]}`).join('&');
    }

    if (options) {
      queryString += Object.keys(options).map(key => `${queryString ? '&' : ''}options[${key}]=${options[key]}`).join('&');
    }

    const { data } = await this.fetchData<IFetchUsersAndGroupTreeResponse>({
      queryString: `people-picker?${queryString}`,
      apiVersion: 1,
      signal,
    });

    return data;
  }

  async fetchUsersAndGroupTree(signal: AbortSignal) {
    const { data } = await this.fetchData<IFetchUsersAndGroupTreeResponse>({
      queryString: 'ajax/get_groups_users_list',
      apiVersion: 0,
      signal,
    });

    return data;
  }

  async getInviteUsersLink(params: IGetInviteUsersLink, signal: AbortSignal) {
    const { groupId, expirationPeriodInDays } = params;
    const response = await this.fetchData<Omit<Response, 'json'>>({
      queryString: `invite_link/ajax_get_link/${groupId}/${expirationPeriodInDays}`,
      apiVersion: 0,
      additionalHeaders: {
        Accept: 'text/html',
      },
      readTextHtml: true,
      signal,
    });

    if (!response.ok) {
      return '';
    }

    return await response.data.text();
  }

  async inviteUsersByEmail(groupId: number, params: IPostInviteUsersByEmailParams, signal: AbortSignal) {
    const body = new URLSearchParams();

    for (const key in params) {
      if (key === 'guests') {
        continue;
      }
      if (key === 'projects') {
        body.append(key, JSON.stringify(params[key]));
        continue;
      }
      if (Array.isArray(params[key])) {
        params[key].forEach((value, index) => {
          if (typeof value === 'object') {
            Object.keys(value).forEach((valueKey) => {
              body.append(`${key}[${index}]${valueKey}`, value[valueKey]);
            });
          } else {
            body.append(`${key}[${index}]`, value);
          }
        });
      } else {
        body.append(key, params[key]);
      }
    }

    if (Array.isArray(params.guests)) {
      params.guests.forEach((guest, index) => {
        body.append(`guests[${index}][email]`, guest.email);
        body.append(`guests[${index}][guest]`, guest.guest ? 'true' : '');
      });
    }

    return await this.fetchData<IPostInviteUserByEmailResponse>({
      queryString: `group/${groupId}/user`,
      apiVersion: 1,
      method: 'POST',
      additionalHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(body.toString()),
      signal,
    });
  }

  async inviteUsersBulk(params: IInviteUsersBulk, signal: AbortSignal) {
    const body = new URLSearchParams();

    const { users, user_event_place } = params;
    const { csrfEnabled, csrfTokenName, csrfCookieName } = config;

    users.forEach((user, index) => {
      Object.entries(user).forEach(([key, value]) => {
        if (value !== undefined) {
          body.append(`users[${index}][${key}]`, value);
        }
      });
    });

    body.append('user_event_place', user_event_place);
    if (csrfEnabled) {
      body.append(csrfTokenName, getCSRFCookieValue(csrfCookieName));
    }

    return await this.fetchData({
      queryString: 'groups/ajax_bulk_import',
      apiVersion: 0,
      method: 'POST',
      additionalHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'text/html',
      },
      readTextHtml: true,
      body: new URLSearchParams(body.toString()),
      signal,
    });
  }
}
