export const decodeHtmlEntities = (str: string) => str
  .replace(/&amp;/g, '&')
  .replace(/&lt;/g, '<')
  .replace(/&gt;/g, '>')
  .replace(/&quot;/g, '"')
  .replace(/&apos;/g, '\'');

export const normalizeForSearch = (str: string) => str.toLowerCase()
  .normalize('NFD')
  .replace(/\p{Diacritic}/gu, '')
  .replaceAll('ł', 'l');
