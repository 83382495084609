import React from 'react';

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { IconLookup } from '@fortawesome/fontawesome-common-types';

import { renderClasses } from 'utils';

export interface Icon extends Partial<FontAwesomeIconProps> {
  icon?: IconLookup
  name?: 'chart-user-custom-tasks' | 'chart-user-custom-days' | 'chart-user-custom-tasks-and-days' | 'chart-user-custom-projects' | 'magnifying-glass-dollar-custom-estimates' | 'magnifying-glass-dollar-custom-costs' | 'magnifying-glass-dollar-custom-margin' | 'magnifying-glass-dollar-custom-revenue'
  className?: string;
  isCustom?: boolean;
}

export const Icon: React.FC<Icon> = (props: Icon) => {
  const {
    icon,
    name,
    className = '',
    isCustom = false,
    ...iconProps
  } = props;

  if (!icon && !name) {
    return null;
  }

  const { width: widthProp, height: heightProp } = props;

  if (name === 'chart-user-custom-tasks') {
    return (
      <svg className={className} viewBox='0 0 30 24' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.5 15C9.98531 15 12 12.9853 12 10.5C12 8.01469 9.98531 6 7.5 6C4.97344 6 3 7.97344 3 10.5C3 12.9844 4.97344 15 7.5 15ZM7.5 7.5C9.15422 7.5 10.5 8.84578 10.5 10.5C10.5 12.1542 9.15469 13.5 7.5 13.5C5.84531 13.5 4.5 12.1547 4.5 10.5C4.5 8.84531 5.84531 7.5 7.5 7.5ZM27.75 0H9.75C8.50781 0 7.5 1.00922 7.5 2.25V3.75C7.5 4.16438 7.8375 4.5 8.25 4.5C8.6625 4.5 9 4.16438 9 3.75V2.25C9 1.83703 9.3375 1.5 9.75 1.5H27.75C28.1625 1.5 28.5 1.83703 28.5 2.25V17.25C28.5 17.6631 28.1638 18 27.75 18H16.5C16.0854 18 15.75 18.3354 15.75 18.75C15.75 19.1646 16.0875 19.5 16.5 19.5H27.75C28.9922 19.5 30 18.4922 30 17.25V2.25C30 1.00922 28.9922 0 27.75 0ZM9 16.5H6C2.68641 16.5 0 19.1864 0 22.5C0 23.3283 0.671719 24 1.5 24H13.5C14.3283 24 15 23.3283 15 22.5C15 19.1859 12.3141 16.5 9 16.5ZM1.5 22.5C1.5 20.0184 3.51891 18 6 18H9C11.4816 18 13.5 20.0184 13.5 22.5H1.5Z'
          fill='currentColor'
        />
        <path d='M24.1818 5.73955H20.8684V14.5H19.328V5.73955H16V4.5H24.1818V5.73955Z' fill='currentColor' />
      </svg>
    );
  }
  if (name === 'chart-user-custom-days') {
    return (
      <svg className={className} viewBox='0 0 30 24' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.5 15C9.98531 15 12 12.9853 12 10.5C12 8.01469 9.98531 6 7.5 6C4.97344 6 3 7.97344 3 10.5C3 12.9844 4.97344 15 7.5 15ZM7.5 7.5C9.15422 7.5 10.5 8.84578 10.5 10.5C10.5 12.1542 9.15469 13.5 7.5 13.5C5.84531 13.5 4.5 12.1547 4.5 10.5C4.5 8.84531 5.84531 7.5 7.5 7.5ZM27.75 0H9.75C8.50781 0 7.5 1.00922 7.5 2.25V3.75C7.5 4.16438 7.8375 4.5 8.25 4.5C8.6625 4.5 9 4.16438 9 3.75V2.25C9 1.83703 9.3375 1.5 9.75 1.5H27.75C28.1625 1.5 28.5 1.83703 28.5 2.25V17.25C28.5 17.6631 28.1638 18 27.75 18H16.5C16.0854 18 15.75 18.3354 15.75 18.75C15.75 19.1646 16.0875 19.5 16.5 19.5H27.75C28.9922 19.5 30 18.4922 30 17.25V2.25C30 1.00922 28.9922 0 27.75 0ZM9 16.5H6C2.68641 16.5 0 19.1864 0 22.5C0 23.3283 0.671719 24 1.5 24H13.5C14.3283 24 15 23.3283 15 22.5C15 19.1859 12.3141 16.5 9 16.5ZM1.5 22.5C1.5 20.0184 3.51891 18 6 18H9C11.4816 18 13.5 20.0184 13.5 22.5H1.5Z'
          fill='currentColor'
        />
        <path
          d='M24.7256 9.5C24.7256 10.2474 24.6049 10.9276 24.3635 11.5404C24.1267 12.1532 23.7902 12.6801 23.3538 13.1212C22.9174 13.5576 22.3951 13.8965 21.7869 14.1379C21.1787 14.3793 20.5032 14.5 19.7604 14.5H16V4.5H19.7604C20.5032 4.5 21.1787 4.62071 21.7869 4.86212C22.3951 5.10353 22.9174 5.44475 23.3538 5.88579C23.7902 6.32219 24.1267 6.84912 24.3635 7.46658C24.6049 8.07939 24.7256 8.7572 24.7256 9.5ZM23.2075 9.5C23.2075 8.90576 23.1263 8.37419 22.9638 7.90529C22.8013 7.43175 22.5692 7.0325 22.2674 6.70752C21.9703 6.3779 21.6082 6.12721 21.1811 5.95543C20.7586 5.78366 20.2851 5.69777 19.7604 5.69777H17.4902V13.3022H19.7604C20.2851 13.3022 20.7586 13.2163 21.1811 13.0446C21.6082 12.8728 21.9703 12.6244 22.2674 12.2994C22.5692 11.9698 22.8013 11.5706 22.9638 11.1017C23.1263 10.6328 23.2075 10.0989 23.2075 9.5Z'
          fill='currentColor'
        />
      </svg>
    );
  }
  if (name === 'chart-user-custom-tasks-and-days') {
    return (
      <svg
        className={className}
        viewBox='0 0 30 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.5 15C9.98531 15 12 12.9853 12 10.5C12 8.01469 9.98531 6 7.5 6C4.97344 6 3 7.97344 3 10.5C3 12.9844 4.97344 15 7.5 15ZM7.5 7.5C9.15422 7.5 10.5 8.84578 10.5 10.5C10.5 12.1542 9.15469 13.5 7.5 13.5C5.84531 13.5 4.5 12.1547 4.5 10.5C4.5 8.84531 5.84531 7.5 7.5 7.5ZM27.75 0H9.75C8.50781 0 7.5 1.00922 7.5 2.25V3.75C7.5 4.16438 7.8375 4.5 8.25 4.5C8.6625 4.5 9 4.16438 9 3.75V2.25C9 1.83703 9.3375 1.5 9.75 1.5H27.75C28.1625 1.5 28.5 1.83703 28.5 2.25V17.25C28.5 17.6631 28.1638 18 27.75 18H16.5C16.0854 18 15.75 18.3354 15.75 18.75C15.75 19.1646 16.0875 19.5 16.5 19.5H27.75C28.9922 19.5 30 18.4922 30 17.25V2.25C30 1.00922 28.9922 0 27.75 0ZM9 16.5H6C2.68641 16.5 0 19.1864 0 22.5C0 23.3283 0.671719 24 1.5 24H13.5C14.3283 24 15 23.3283 15 22.5C15 19.1859 12.3141 16.5 9 16.5ZM1.5 22.5C1.5 20.0184 3.51891 18 6 18H9C11.4816 18 13.5 20.0184 13.5 22.5H1.5Z'
          fill='currentColor'
        />
        <path d='M28 6.49164H25.2179V13.5H23.9244V6.49164H21.1301V5.5H28V6.49164Z' fill='currentColor' />
        <path
          d='M20.6448 9.5C20.6448 10.098 20.539 10.6421 20.3275 11.1323C20.1201 11.6226 19.8252 12.0441 19.4428 12.3969C19.0605 12.7461 18.6029 13.0172 18.0701 13.2103C17.5372 13.4034 16.9454 13.5 16.2946 13.5H13V5.5H16.2946C16.9454 5.5 17.5372 5.59656 18.0701 5.78969C18.6029 5.98282 19.0605 6.2558 19.4428 6.60863C19.8252 6.95775 20.1201 7.3793 20.3275 7.87326C20.539 8.36351 20.6448 8.90576 20.6448 9.5ZM19.3147 9.5C19.3147 9.02461 19.2435 8.59935 19.1012 8.22423C18.9588 7.8454 18.7554 7.526 18.491 7.26602C18.2307 7.00232 17.9135 6.80176 17.5393 6.66435C17.1691 6.52693 16.7542 6.45822 16.2946 6.45822H14.3056V12.5418H16.2946C16.7542 12.5418 17.1691 12.4731 17.5393 12.3357C17.9135 12.1982 18.2307 11.9995 18.491 11.7396C18.7554 11.4759 18.9588 11.1565 19.1012 10.7813C19.2435 10.4062 19.3147 9.97911 19.3147 9.5Z'
          fill='currentColor'
        />
      </svg>
    );
  }
  if (name === 'chart-user-custom-projects') {
    return (
      <svg
        className={className}
        viewBox='0 0 30 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.5 15C9.98531 15 12 12.9853 12 10.5C12 8.01469 9.98531 6 7.5 6C4.97344 6 3 7.97344 3 10.5C3 12.9844 4.97344 15 7.5 15ZM7.5 7.5C9.15422 7.5 10.5 8.84578 10.5 10.5C10.5 12.1542 9.15469 13.5 7.5 13.5C5.84531 13.5 4.5 12.1547 4.5 10.5C4.5 8.84531 5.84531 7.5 7.5 7.5ZM27.75 0H9.75C8.50781 0 7.5 1.00922 7.5 2.25V3.75C7.5 4.16438 7.8375 4.5 8.25 4.5C8.6625 4.5 9 4.16438 9 3.75V2.25C9 1.83703 9.3375 1.5 9.75 1.5H27.75C28.1625 1.5 28.5 1.83703 28.5 2.25V17.25C28.5 17.6631 28.1638 18 27.75 18H16.5C16.0854 18 15.75 18.3354 15.75 18.75C15.75 19.1646 16.0875 19.5 16.5 19.5H27.75C28.9922 19.5 30 18.4922 30 17.25V2.25C30 1.00922 28.9922 0 27.75 0ZM9 16.5H6C2.68641 16.5 0 19.1864 0 22.5C0 23.3283 0.671719 24 1.5 24H13.5C14.3283 24 15 23.3283 15 22.5C15 19.1859 12.3141 16.5 9 16.5ZM1.5 22.5C1.5 20.0184 3.51891 18 6 18H9C11.4816 18 13.5 20.0184 13.5 22.5H1.5Z'
          fill='currentColor'
        />
        <path
          d='M20.0292 9.64624C20.4053 9.64624 20.7349 9.59749 21.0181 9.5C21.3059 9.40251 21.545 9.26555 21.7354 9.08914C21.9304 8.90808 22.0766 8.69452 22.1741 8.44847C22.2716 8.19777 22.3203 7.92154 22.3203 7.61978C22.3203 7.01161 22.13 6.53343 21.7493 6.18524C21.3733 5.83705 20.7999 5.66295 20.0292 5.66295H18.4833V9.64624H20.0292ZM20.0292 4.5C20.6699 4.5 21.227 4.57428 21.7006 4.72284C22.1741 4.8714 22.5664 5.08264 22.8774 5.35655C23.1885 5.62581 23.4183 5.95311 23.5669 6.33844C23.7201 6.72377 23.7967 7.15088 23.7967 7.61978C23.7967 8.08867 23.7154 8.52043 23.5529 8.91504C23.3904 9.30501 23.1513 9.6416 22.8357 9.92479C22.52 10.208 22.1253 10.4285 21.6518 10.5864C21.1829 10.7396 20.6421 10.8162 20.0292 10.8162H18.4833V14.5H17V4.5H20.0292Z'
          fill='currentColor'
        />
      </svg>
    );
  }
  if (name === 'magnifying-glass-dollar-custom-estimates') {
    return (
      <svg className={className} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.58895 18.9586L4.34668 18.8894C3.16512 18.5402 3.06668 18.3047 3.14051 17.8863C3.23993 17.3073 4.0602 17.1698 4.83012 17.2918C5.11246 17.3347 5.43867 17.4413 5.65945 17.5182C5.95224 17.617 6.27293 17.4633 6.37523 17.1699C6.47687 16.8766 6.32085 16.5568 6.0275 16.4548C5.61199 16.3113 5.28152 16.2234 5.00027 16.1812V15.5625C5.00027 15.2516 4.7487 15 4.43777 15C4.12685 15 3.87527 15.2517 3.87527 15.5625V16.157C2.88035 16.2867 2.18074 16.8387 2.03309 17.6965C1.75567 19.3007 3.29274 19.7521 4.03137 19.9686L4.28129 20.0411C5.56629 20.407 5.82293 20.5969 5.73504 21.1137C5.6366 21.6937 4.81746 21.8309 4.04402 21.7078C3.70301 21.6551 3.29168 21.5074 2.92957 21.3773L2.75027 21.3141C2.45496 21.2051 2.13856 21.3316 2.03309 21.6551C1.92927 21.9484 2.08199 22.2693 2.37421 22.3737L2.54637 22.4355C2.9577 22.585 3.42633 22.7532 3.87246 22.8202L3.87527 23.4375C3.87527 23.7484 4.12685 24 4.43777 24C4.7487 24 5.00027 23.7469 5.00027 23.4375V22.8469C5.99695 22.7194 6.69516 22.1631 6.84316 21.3053C7.12371 19.6828 5.59793 19.2469 4.58895 18.9586Z'
          fill='currentColor'
        />
        <path
          d='M2.16184 13C1.73547 12.002 1.5 10.9032 1.5 9.75C1.5 5.20312 5.16094 1.5 9.75 1.5C14.3391 1.5 18 5.16094 18 9.75C18 14.2547 14.4318 17.865 10 17.9963V19.4968C12.3245 19.438 14.4424 18.5608 16.0875 17.1492L22.7203 23.782C22.8656 23.925 23.0578 24 23.25 24C23.4422 24 23.6338 23.9268 23.7802 23.7803C24.075 23.4891 24.075 23.0109 23.7797 22.7203L17.1469 16.0875C18.6094 14.3813 19.5 12.1734 19.5 9.75C19.5 4.36547 15.1359 0 9.75 0C4.36406 0 0 4.36547 0 9.75C0 10.8895 0.195525 11.9835 0.554854 13H2.16184Z'
          fill='currentColor'
        />
        <path
          d='M12.5843 12.9157L12.578 14H7V5H12.578V6.08426H8.34123V8.93593H11.7507V9.98259H8.34123V12.9157H12.5843Z'
          fill='currentColor'
        />
      </svg>
    );
  }
  if (name === 'magnifying-glass-dollar-custom-revenue') {
    return (
      <svg className={className} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.58895 18.9586L4.34668 18.8894C3.16512 18.5402 3.06668 18.3047 3.14051 17.8863C3.23993 17.3073 4.0602 17.1698 4.83012 17.2918C5.11246 17.3347 5.43867 17.4413 5.65945 17.5182C5.95224 17.617 6.27293 17.4633 6.37523 17.1699C6.47687 16.8766 6.32085 16.5568 6.0275 16.4548C5.61199 16.3113 5.28152 16.2234 5.00027 16.1812V15.5625C5.00027 15.2516 4.7487 15 4.43777 15C4.12685 15 3.87527 15.2517 3.87527 15.5625V16.157C2.88035 16.2867 2.18074 16.8387 2.03309 17.6965C1.75567 19.3007 3.29274 19.7521 4.03137 19.9686L4.28129 20.0411C5.56629 20.407 5.82293 20.5969 5.73504 21.1137C5.6366 21.6937 4.81746 21.8309 4.04402 21.7078C3.70301 21.6551 3.29168 21.5074 2.92957 21.3773L2.75027 21.3141C2.45496 21.2051 2.13856 21.3316 2.03309 21.6551C1.92927 21.9484 2.08199 22.2693 2.37421 22.3737L2.54637 22.4355C2.9577 22.585 3.42633 22.7532 3.87246 22.8202L3.87527 23.4375C3.87527 23.7484 4.12685 24 4.43777 24C4.7487 24 5.00027 23.7469 5.00027 23.4375V22.8469C5.99695 22.7194 6.69516 22.1631 6.84316 21.3053C7.12371 19.6828 5.59793 19.2469 4.58895 18.9586Z'
          fill='currentColor'
        />
        <path
          d='M2.16184 13C1.73547 12.002 1.5 10.9032 1.5 9.75C1.5 5.20312 5.16094 1.5 9.75 1.5C14.3391 1.5 18 5.16094 18 9.75C18 14.2547 14.4318 17.865 10 17.9963V19.4968C12.3245 19.438 14.4424 18.5608 16.0875 17.1492L22.7203 23.782C22.8656 23.925 23.0578 24 23.25 24C23.4422 24 23.6338 23.9268 23.7802 23.7803C24.075 23.4891 24.075 23.0109 23.7797 22.7203L17.1469 16.0875C18.6094 14.3813 19.5 12.1734 19.5 9.75C19.5 4.36547 15.1359 0 9.75 0C4.36406 0 0 4.36547 0 9.75C0 10.8895 0.195525 11.9835 0.554854 13H2.16184Z'
          fill='currentColor'
        />
        <path
          d='M9.55084 9.33705C9.89345 9.33705 10.1943 9.29527 10.4533 9.2117C10.7166 9.12813 10.9359 9.01114 11.1114 8.86072C11.2869 8.70613 11.4185 8.52437 11.5063 8.31546C11.594 8.10237 11.6379 7.86839 11.6379 7.61351C11.6379 7.09541 11.4666 6.70474 11.124 6.4415C10.7855 6.17827 10.2758 6.04666 9.59471 6.04666H8.33496V9.33705H9.55084ZM13.75 14H12.5592C12.321 14 12.1476 13.906 12.039 13.718L9.81407 10.5968C9.74304 10.4923 9.66574 10.4192 9.58217 10.3774C9.49861 10.3315 9.37326 10.3085 9.20613 10.3085H8.33496V14H7V5H9.59471C10.1755 5 10.6748 5.06059 11.0926 5.18176C11.5146 5.29875 11.8614 5.46797 12.133 5.68942C12.4088 5.90669 12.6114 6.16992 12.7409 6.47911C12.8747 6.7883 12.9415 7.1351 12.9415 7.5195C12.9415 7.83287 12.8935 8.12744 12.7974 8.4032C12.7013 8.67897 12.5592 8.92758 12.3712 9.14903C12.1873 9.3663 11.9617 9.55432 11.6943 9.71309C11.4269 9.87187 11.124 9.99304 10.7855 10.0766C10.9526 10.1769 11.0968 10.3169 11.218 10.4965L13.75 14Z'
          fill='currentColor'
        />
      </svg>
    );
  }
  if (name === 'magnifying-glass-dollar-custom-costs') {
    return (
      <svg className={className} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.58895 18.9586L4.34668 18.8894C3.16512 18.5402 3.06668 18.3047 3.14051 17.8863C3.23993 17.3073 4.0602 17.1698 4.83012 17.2918C5.11246 17.3347 5.43867 17.4413 5.65945 17.5182C5.95224 17.617 6.27293 17.4633 6.37523 17.1699C6.47687 16.8766 6.32085 16.5568 6.0275 16.4548C5.61199 16.3113 5.28152 16.2234 5.00027 16.1812V15.5625C5.00027 15.2516 4.7487 15 4.43777 15C4.12685 15 3.87527 15.2517 3.87527 15.5625V16.157C2.88035 16.2867 2.18074 16.8387 2.03309 17.6965C1.75567 19.3007 3.29274 19.7521 4.03137 19.9686L4.28129 20.0411C5.56629 20.407 5.82293 20.5969 5.73504 21.1137C5.6366 21.6937 4.81746 21.8309 4.04402 21.7078C3.70301 21.6551 3.29168 21.5074 2.92957 21.3773L2.75027 21.3141C2.45496 21.2051 2.13856 21.3316 2.03309 21.6551C1.92927 21.9484 2.08199 22.2693 2.37421 22.3737L2.54637 22.4355C2.9577 22.585 3.42633 22.7532 3.87246 22.8202L3.87527 23.4375C3.87527 23.7484 4.12685 24 4.43777 24C4.7487 24 5.00027 23.7469 5.00027 23.4375V22.8469C5.99695 22.7194 6.69516 22.1631 6.84316 21.3053C7.12371 19.6828 5.59793 19.2469 4.58895 18.9586Z'
          fill='currentColor'
        />
        <path
          d='M2.16184 13C1.73547 12.002 1.5 10.9032 1.5 9.75C1.5 5.20312 5.16094 1.5 9.75 1.5C14.3391 1.5 18 5.16094 18 9.75C18 14.2547 14.4318 17.865 10 17.9963V19.4968C12.3245 19.438 14.4424 18.5608 16.0875 17.1492L22.7203 23.782C22.8656 23.925 23.0578 24 23.25 24C23.4422 24 23.6338 23.9268 23.7802 23.7803C24.075 23.4891 24.075 23.0109 23.7797 22.7203L17.1469 16.0875C18.6094 14.3813 19.5 12.1734 19.5 9.75C19.5 4.36547 15.1359 0 9.75 0C4.36406 0 0 4.36547 0 9.75C0 10.8895 0.195525 11.9835 0.554854 13H2.16184Z'
          fill='currentColor'
        />
        <path
          d='M12.1826 12.0136C12.2561 12.0136 12.3195 12.0422 12.3726 12.0995L12.8876 12.6635C12.5279 13.0886 12.0886 13.4176 11.5695 13.6505C11.0545 13.8835 10.4332 14 9.70572 14C9.06812 14 8.48978 13.8896 7.97071 13.6689C7.45572 13.4482 7.01431 13.1396 6.64646 12.7432C6.2827 12.3467 6.00068 11.8726 5.80041 11.3208C5.60014 10.7691 5.5 10.1621 5.5 9.5C5.5 8.83787 5.60422 8.23093 5.81267 7.67915C6.0252 7.12738 6.32153 6.65327 6.70163 6.25681C7.08174 5.86035 7.53542 5.55177 8.06267 5.33106C8.59401 5.11035 9.18052 5 9.82221 5C10.4475 5 11.0034 5.10218 11.4898 5.30654C11.9762 5.5109 12.3992 5.7827 12.7589 6.12193L12.3297 6.72888C12.3011 6.77384 12.2643 6.81063 12.2193 6.83924C12.1785 6.86785 12.1233 6.88215 12.0538 6.88215C11.9762 6.88215 11.8842 6.84332 11.7779 6.76567C11.6757 6.68801 11.5388 6.60218 11.3672 6.50817C11.1996 6.41008 10.9911 6.32221 10.7418 6.24455C10.4925 6.16689 10.1819 6.12807 9.80995 6.12807C9.37262 6.12807 8.97207 6.20572 8.60831 6.36103C8.24455 6.51226 7.92984 6.73297 7.66417 7.02316C7.40259 7.30926 7.19823 7.66281 7.05109 8.08379C6.90804 8.50068 6.83651 8.97275 6.83651 9.5C6.83651 10.0354 6.91213 10.5116 7.06335 10.9285C7.21458 11.3454 7.42302 11.6989 7.68869 11.9891C7.95436 12.2793 8.26703 12.5 8.6267 12.6512C8.98638 12.8025 9.37466 12.8781 9.79155 12.8781C10.045 12.8781 10.2718 12.8638 10.4721 12.8351C10.6764 12.8025 10.8624 12.7554 11.03 12.6941C11.2016 12.6328 11.3631 12.5552 11.5143 12.4612C11.6655 12.3631 11.8147 12.2466 11.9619 12.1117C11.9946 12.0831 12.0293 12.0606 12.0661 12.0443C12.1029 12.0238 12.1417 12.0136 12.1826 12.0136Z'
          fill='currentColor'
        />
      </svg>
    );
  }
  if (name === 'magnifying-glass-dollar-custom-margin') {
    return (
      <svg className={className} viewBox='0 0 25 24' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.58895 18.9586L4.34668 18.8894C3.16512 18.5402 3.06668 18.3047 3.14051 17.8863C3.23993 17.3073 4.0602 17.1698 4.83012 17.2918C5.11246 17.3347 5.43867 17.4413 5.65945 17.5182C5.95224 17.617 6.27293 17.4633 6.37523 17.1699C6.47687 16.8766 6.32085 16.5568 6.0275 16.4548C5.61199 16.3113 5.28152 16.2234 5.00027 16.1812V15.5625C5.00027 15.2516 4.7487 15 4.43777 15C4.12685 15 3.87527 15.2517 3.87527 15.5625V16.157C2.88035 16.2867 2.18074 16.8387 2.03309 17.6965C1.75567 19.3007 3.29274 19.7521 4.03137 19.9686L4.28129 20.0411C5.56629 20.407 5.82293 20.5969 5.73504 21.1137C5.6366 21.6937 4.81746 21.8309 4.04402 21.7078C3.70301 21.6551 3.29168 21.5074 2.92957 21.3773L2.75027 21.3141C2.45496 21.2051 2.13856 21.3316 2.03309 21.6551C1.92927 21.9484 2.08199 22.2693 2.37421 22.3737L2.54637 22.4355C2.9577 22.585 3.42633 22.7532 3.87246 22.8202L3.87527 23.4375C3.87527 23.7484 4.12685 24 4.43777 24C4.7487 24 5.00027 23.7469 5.00027 23.4375V22.8469C5.99695 22.7194 6.69516 22.1631 6.84316 21.3053C7.12371 19.6828 5.59793 19.2469 4.58895 18.9586Z'
          fill='currentColor'
        />
        <path
          d='M14.4638 5V14H13.298V7.59471C13.298 7.49861 13.3001 7.39624 13.3043 7.28761C13.3127 7.17897 13.321 7.06824 13.3294 6.95543L10.3336 12.4519C10.2291 12.6567 10.0682 12.7591 9.85098 12.7591H9.66295C9.44568 12.7591 9.28691 12.6567 9.18663 12.4519L6.1344 6.93663C6.15947 7.17061 6.17201 7.38997 6.17201 7.59471V14H5V5H5.99025C6.10724 5 6.19708 5.01254 6.25975 5.03761C6.3266 5.0585 6.38928 5.12117 6.44777 5.22563L9.45613 10.6156C9.51462 10.7242 9.56894 10.8391 9.61908 10.9603C9.66922 11.0815 9.71727 11.2026 9.76323 11.3238C9.85098 11.0773 9.95334 10.8391 10.0703 10.6093L13.0223 5.22563C13.0766 5.12117 13.1351 5.0585 13.1978 5.03761C13.2646 5.01254 13.3565 5 13.4735 5H14.4638Z'
          fill='currentColor'
        />
        <path
          d='M2.16184 13C1.73547 12.002 1.5 10.9032 1.5 9.75C1.5 5.20312 5.16094 1.5 9.75 1.5C14.3391 1.5 18 5.16094 18 9.75C18 14.2547 14.4318 17.865 10 17.9963V19.4968C12.3245 19.438 14.4424 18.5608 16.0875 17.1492L22.7203 23.782C22.8656 23.925 23.0578 24 23.25 24C23.4422 24 23.6338 23.9268 23.7802 23.7803C24.075 23.4891 24.075 23.0109 23.7797 22.7203L17.1469 16.0875C18.6094 14.3813 19.5 12.1734 19.5 9.75C19.5 4.36547 15.1359 0 9.75 0C4.36406 0 0 4.36547 0 9.75C0 10.8895 0.195525 11.9835 0.554854 13H2.16184Z'
          fill='currentColor'
        />
      </svg>
    );
  }
  if (name === 'chart-mixed-custom') {
    return (
      <svg
        className={className}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 512 512'
      >
        <path
          fill='currentColor'
          d='M80 432V368c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16zM192 255.1c-8.8 0-16 8.1-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V271.1c0-7.9-7.2-16-16-16zM336 432V336c0-8.8-7.2-16.9-16-16.9s-16 8.1-16 16.9v96c0 8.8 7.2 16 16 16s16-7.2 16-16zM448 255.1c-8.8 0-16 8.1-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V271.1c0-7.9-7.2-16-16-16zM507.8 37.2c6 6.5 5.5 16.6-1 22.6l-176 160c-6.4 5.8-16.1 5.6-22.1-.5L190.4 100.1 25.4 220.9c-7.1 5.2-17.2 3.7-22.3-3.5c-5.2-7.1-3.6-17.1 3.5-22.3l176-128c5.5-4.6 15.1-3.9 20.7 1.6L320.5 185.9 485.2 36.2c6.6-5.9 16.7-5.5 22.6 1.1z'
        />
      </svg>
    );
  }

  if (isCustom) {
    return (
      <i
        className={renderClasses({
          [className]: true,
          fak: true,
          [name]: true,
        })}
        style={{ width: widthProp, height: heightProp }}
      />
    );
  }

  return <FontAwesomeIcon icon={icon} className={className} {...iconProps} />;
};
