export type GoogleSpreadsheetExporterEventsType =
  'google-spreadsheet-exporting:initiated' |
  'google-spreadsheet-exporting:error' |
  'google-spreadsheet-exporting:success' |
  'google-spreadsheet-exporting:finished';

export class GoogleSpreadsheetExporterEvents {

  static dispatchEvent(eventName: GoogleSpreadsheetExporterEventsType, data?: Record<string, string | number | boolean>) {
    const event = new CustomEvent(eventName, { detail: data });
    window.dispatchEvent(event);
  }

  static addEventListener(eventName: GoogleSpreadsheetExporterEventsType, callback: (e) => void) {
    window.addEventListener(eventName, callback);
  }

  static removeEventListener(eventName: GoogleSpreadsheetExporterEventsType, callback: (e) => void) {
    window.removeEventListener(eventName, callback);
  }
}
